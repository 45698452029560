import TextSection, { Section } from '@/features/profile/components/TextSection'
import { GeneralError, HelpPrompt, MainLayout, SplitLayout } from '@/shared/components'
import { PrimaryButton, Stack } from '@fluentui/react'
import { useGetProfileQuery } from '@/shared/api/services/profileServices'
import { useMemo, useState } from 'react'
import ProfileAvatar from '@/features/profile/components/ProfileAvatar'
import { useSelector } from 'react-redux'
import { selectAccountId } from '@/features/auth/redux/authSelectors'
import { useGetDocSignLinkQuery } from '@/shared/api/services/docSignService'
import DocuSignEmbedded from './DocSign'

const Profile = () => {
  const reduxAccountId = useSelector(selectAccountId);
  const { data, isError, isUninitialized, isLoading, refetch } = useGetProfileQuery({ accountId: reduxAccountId })
  const { data:data1, isUninitialized:isUninitialized1, isLoading:isLoading1 } = useGetDocSignLinkQuery()
  const [showDocuSign, setShowDocuSign] = useState(false);
  const textSections: Array<Section> = useMemo(() => { 
    if (!data) return []
    const sections = [
      { label: 'First Name', value: data.firstName },
      { label: 'Last Name', value: data.lastName },
      { label: 'Account', value: data.clientName },
      { label: 'Email', value: data.email },
      { label: 'Phone', value: data.phoneNumber }
    ]
    // Only return sections that have a value
    return sections.filter((section) => section.value)
   }, [data])

   const handleOpenDocuSign = () => {
    setShowDocuSign(true);
  };
  
  return (
    <MainLayout>
      <SplitLayout 
        className='c-profile'
        renderHeader={false}
        leftContent={
          <Stack className='c-profile__content__container__left'>
            <h2 className='c-profile__header'>My Profile</h2>
            {
              isError
              ? (
                <GeneralError onClick={refetch} title='Failed to get profile info' />
              )
              : (
                <>
                  <ProfileAvatar />
                  <TextSection 
                    isLoading={isUninitialized || isLoading}
                    title='Name & contact information' 
                    sections={textSections}
                  />
                </>
              )
            }
          </Stack>
          }
        rightContent={
          <Stack className='c-profile__content__container__right'>
            {/* <Stack.Item>
              <TeamCard />
            </Stack.Item> */}
            {/* <OfficeCard /> */}
            <Stack.Item>
              <HelpPrompt />
            </Stack.Item>
          </Stack>
          }
      />
      <div>
        <h1>Sign Document</h1>
        <PrimaryButton onClick={handleOpenDocuSign}>Open DocuSign</PrimaryButton>
        {showDocuSign && !isLoading1 && <DocuSignEmbedded signingUrl={ data1 } />}
      </div>
    </MainLayout>
  )
}

export default Profile