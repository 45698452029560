import { IUserProfileModel } from '@/shared/types/swagger'
import { emmaApiService } from '.'

const serviceWithTags = emmaApiService.enhanceEndpoints({ addTagTypes: ['DocLink'],  })

const docSignService = serviceWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getDocSignLink: build.query<string, void>({
        query: () => ({ url: '/signing/signingUrl', method: 'GET' }),
        providesTags: ['DocLink'],
        keepUnusedDataFor: 6000
      }),
    }
  },
})

export const { useGetDocSignLinkQuery  } = docSignService
