import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { withTranslation } from 'react-i18next/*';


declare global {
  interface Window {
    DocuSign: any;
  }
}

interface DocuSignEmbeddedProps {
  signingUrl: string;
}

const DocuSignEmbedded :React.FC<DocuSignEmbeddedProps>= ({ signingUrl }) => {
    // const [signingUrl, setSigningUrl] = useState(null);
    const [isSessionEnd, setIsSessionEnd] = useState(false)
  // const { data, isUninitialized, isLoading } = useGetExhibitLinkQuery()

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.DocuSign ) {
      // setSigningUrl(data)
      console.log(signingUrl);
      // console.log(data)
      window.DocuSign.loadDocuSign('f179a879-2d10-4185-9b76-309b3cc2ff2c')
        .then((docusign: any) => {
          const signing = docusign.signing({
            url: signingUrl,
            displayFormat: 'focused',
            style: {
              branding: {
                primaryButton: {
                  backgroundColor: '#333',
                  color: '#fff',
                },
              },
              signingNavigationButton: {
                finishText: 'Complete Signing',
                position: 'bottom-left',
              },
            },
          });
          console.log('signingUrl', signingUrl, 'docusign', docusign)
          signing.on('ready', () => console.log('DocuSign UI is ready'));
          signing.on('sessionEnd', (event: any) => {
            setIsSessionEnd(true);
            console.log('Session ended', event);
            switch (event.sessionEndType) {
              case 'signing_complete':
                console.log('Signing complete');
                break;
              case 'cancel':
                console.log('Signing cancelled');
                break;
              case 'decline':
                console.log('Signing declined');
                break;
              case 'exception':
                console.error('Exception occurred');
                break;
              case 'fax_pending':
                console.log('Fax pending');
                break;
              case 'session_timeout':
                console.log('Session timeout');
                break;
              case 'ttl_expired':
                console.log('TTL expired');
                break;
              case 'Viewing_complete':
                console.log('Viewing complete');
                break;
              default:
                console.log('Unknown session end reason');
            }
          });
          signing.mount(containerRef.current);
        })
        .catch((error: any) => {
          console.error('Error loading DocuSign:', error);
        });
    }
  }, [signingUrl]);

  return (
    <div
      className="docusign-container"
      ref={containerRef}
      style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      {isSessionEnd && <Alert > Session Ended </Alert>}
    </div>
  );
};

export default DocuSignEmbedded;